// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

//Import Components
import SectionTitle from '../../components/Shared/SectionTitle';
import BlogBox from '../../components/Shared/BlogBox';

// Blog Images
import blog1 from '../../assets/images/blog/01.jpg';
import blog2 from '../../assets/images/blog/02.jpg';
import blog3 from '../../assets/images/blog/03.jpg';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [
        {
          id: 1,
          image: blog1,
          title: 'Design your apps in your own way',
          like: '33',
          comment: '08',
          autor: 'Calvin Carlo',
          date: '13th August, 2019',
        },
        {
          id: 2,
          image: blog2,
          title: 'How apps is changing the IT world',
          like: '33',
          comment: '08',
          autor: 'Calvin Carlo',
          date: '13th August, 2019',
        },
        {
          id: 3,
          image: blog3,
          title: 'Smartest Applications for Business',
          like: '33',
          comment: '08',
          autor: 'Calvin Carlo',
          date: '13th August, 2019',
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Latest News"
              desc=" that can provide everything you need to generate awareness, drive traffic, connect"
            />

            <Row>
              {/* blog box */}
              <BlogBox blogs={this.state.blogs} />
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs="12" className="text-center">
                <div className="section-title">
                  <h4 className="title mb-4">
                    See everything about your employee at one place.
                  </h4>
                  <p className="text-muted para-desc mx-auto mb-0">
                    Start working with{' '}
                    <span className="text-primary fw-bold">Watopa</span> that
                    can provide everything you need to generate awareness, drive
                    traffic, connect.
                  </p>

                  <div className="mt-4">
                    <Link to="#" className="btn btn-primary mt-2 me-2">
                      Get Started Now
                    </Link>{' '}
                    <Link to="#" className="btn btn-outline-primary mt-2">
                      Free Trial
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default News;
