import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

//Import Components
import FeatureBox from './FeatureBox';

//Import Images
import img1 from '../../assets/images/icon/code.svg';
import img2 from '../../assets/images/icon/chatbot.png';
import img3 from '../../assets/images/rocket.svg';
import img4 from '../../assets/images/icon/intellectual.svg';
import img5 from '../../assets/images/icon/smartphone.svg';
import img6 from '../../assets/images/icon/chat.svg';

class Features extends Component {
  state = {
    features: [
      {
        title: 'Software Personalizado',
        image: img1,
        desc: 'Potencia tus operaciones con soluciones a medida. Diseño y eficiencia en cada línea de código.',
        link: '',
      },
      {
        title: 'Proyectos Innovadores',
        image: img2,
        desc: 'Impulsa la vanguardia tecnológica. Expertos en desarrollo para liderar tu industria.',
        link: '',
      },
      {
        title: 'Desarrolladores Expertos',
        image: img3,
        desc: 'Tu proyecto en manos de profesionales. Resultados excepcionales garantizados.',
        link: '',
      },
      {
        title: 'Desarrollo Ágil',
        image: img4,
        desc: 'Rápido, eficiente, adaptativo. Tu software listo para evolucionar según tus necesidades.',
        link: '',
      },
      {
        title: 'Aseguramiento de Calidad',
        image: img5,
        desc: 'Calidad sin concesiones. Robustez, seguridad y satisfacción para tus usuarios.',
        link: '',
      },
      {
        title: 'Colaboración Clientes',
        image: img6,
        desc: 'Juntos hacia el éxito. Te involucramos en cada paso para lograr resultados excepcionales.',
        link: '',
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title mb-4 pb-2">
                <span
                  className="badge rounded-pill bg-success aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Lo que nos caracteriza
                </span>
                <h4
                  className="title my-4"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  Soluciones de Desarrollo de Software a la Vanguardia
                </h4>
              </div>
            </Col>
          </Row>

          <Row>
            {/* feature box */}
            <FeatureBox features={this.state.features} />
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Features;
