import React from 'react';
import { Redirect } from 'react-router-dom';

//Main Index
import Main from '../pages/Home/indexMain';

//Special

//Auth Pages

// Import all components

import Business from '../pages/Business/index';
import ITSolution from '../pages/ITSolution/index';
import CorporateBusiness from '../pages/CorporateBusiness/index';

import iTSolutionTwo from '../pages/ITSolutionTwo/index';

//Docs

//Components

//Account
import PageProfile from '../pages/Pages/Account/PageProfile';
import PageProfileEdit from '../pages/Pages/Account/PageProfileEdit';
import PagePayments from '../pages/Pages/Account/PagePayments';
import PageInvoice from '../pages/Pages/Account/PageInvoice';
import PageChat from '../pages/Pages/Account/PageChat';

//Blog
import PageBlog from '../pages/Pages/Blog/PageBlog';
import PageBlogDetail from '../pages/Pages/Blog/PageBlogDetail';
import PageBlogDetailTwo from '../pages/Pages/Blog/PageBlogDetailTwo';
import PageBlogSidebar from '../pages/Pages/Blog/PageBlogSidebar';
import PageBlogList from '../pages/Pages/Blog/PageBlogList';
import PageBlogListSidebar from '../pages/Pages/Blog/PageBlogListSidebar';

//Case Study

//course-detail

//Utility

//Contact

// forums

//Email

//Help Center

//Demo Shop

//Corporate Buisness
import CorporateAbout from '../pages/Pages/CorporateAbout';
import CorporateServices from '../pages/Pages/CorporateServices';
import CorporateTeam from '../pages/Pages/CorporateTeam';
import CorporatePricing from '../pages/Pages/CorporatePricing';
import CorporateBlog from '../pages/Pages/CorporateBlog';
import CorporateBlogDetail from '../pages/Pages/CorporateBlogDetail';

//Demo Portfolio

//Career

//Cryptocurrency

//Demo Blog
import Blog from '../pages/Pages/DemoBlog/Blog/index';
import BlogAbout from '../pages/Pages/DemoBlog/BlogAbout';
import BlogStandardPost from '../pages/Pages/DemoBlog/BlogStandardPost';
import BlogSliderPost from '../pages/Pages/DemoBlog/BlogSliderPost';
import BlogGalleryPost from '../pages/Pages/DemoBlog/BlogGalleryPost';
import BlogYoutubePost from '../pages/Pages/DemoBlog/BlogYoutubePost';
import BlogVimeoPost from '../pages/Pages/DemoBlog/BlogVimeoPost';
import BlogAudioPost from '../pages/Pages/DemoBlog/BlogAudioPost';
import BlogBlockquotePost from '../pages/Pages/DemoBlog/BlogBlockquotePost';
import BlogLeftSliderPost from '../pages/Pages/DemoBlog/BlogLeftSidebarPost';

//nft marketplace

//CloudHosting

//Forums

//Footer layouts
import PageFooterLayouts from '../pages/Pages/PageFooterLayouts/PageFooterLayouts';

const routes = [
  //routes without Layout

  //CloudHosting

  //Cryptocurrency

  //Demo Blog
  { path: '/index-blog', component: Blog, isWithoutLayout: true },
  { path: '/blog-about', component: BlogAbout, isWithoutLayout: true },
  {
    path: '/blog-standard-post',
    component: BlogStandardPost,
    isWithoutLayout: true,
  },
  {
    path: '/blog-slider-post',
    component: BlogSliderPost,
    isWithoutLayout: true,
  },
  {
    path: '/blog-gallery-post',
    component: BlogGalleryPost,
    isWithoutLayout: true,
  },
  {
    path: '/blog-youtube-post',
    component: BlogYoutubePost,
    isWithoutLayout: true,
  },
  { path: '/blog-vimeo-post', component: BlogVimeoPost, isWithoutLayout: true },
  { path: '/blog-audio-post', component: BlogAudioPost, isWithoutLayout: true },
  {
    path: '/blog-blockquote-post',
    component: BlogBlockquotePost,
    isWithoutLayout: true,
  },
  {
    path: '/blog-left-sidebar-post',
    component: BlogLeftSliderPost,
    isWithoutLayout: true,
  },

  //nft marketplace

  //Shop page

  //Help Center

  //PortFolio

  //Contact without layout

  //Email Pages
  //Special Pages
  //Demos Corporate Business
  {
    path: '/index-corporate',
    component: CorporateBusiness,
    isWithoutLayout: true,
  },
  {
    path: '/corporate-about',
    component: CorporateAbout,
    isWithoutLayout: true,
  },
  {
    path: '/corporate-services',
    component: CorporateServices,
    isWithoutLayout: true,
  },
  { path: '/corporate-team', component: CorporateTeam, isWithoutLayout: true },
  {
    path: '/corporate-pricing',
    component: CorporatePricing,
    isWithoutLayout: true,
  },
  { path: '/corporate-blog', component: CorporateBlog, isWithoutLayout: true },
  {
    path: '/corporate-blog-detail',
    component: CorporateBlogDetail,
    isWithoutLayout: true,
  },

  //Page Job

  //User Pages
  // Landings
  //Utilityacy, isTopbarDark: true },

  //Page Profile
  //Page Blog
  { path: '/page-blog-grid', component: PageBlog, isTopbarDark: true },
  { path: '/page-blog-detail', component: PageBlogDetail, isTopbarDark: true },
  { path: '/page-blog-detail-two', component: PageBlogDetailTwo },
  {
    path: '/page-blog-sidebar',
    component: PageBlogSidebar,
    isTopbarDark: true,
  },
  { path: '/page-blog-list', component: PageBlogList, isTopbarDark: true },
  {
    path: '/page-blog-list-sidebar',
    component: PageBlogListSidebar,
    isTopbarDark: true,
  },

  //Page Case Study
  //course-detail
  //Page Contact
  // forumsms-comments', component: ForumsComments, isTopbarDark: true },

  //Components
  //Docs
  //Index Main
  { path: '/', component: iTSolutionTwo, isTopbarDark: true, isWithoutLayout: true },
];

export default routes;
