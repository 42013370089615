import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Features = () => {
  return (
    <React.Fragment>
      <section className="py-4 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={6} className="col-12">
              <div className="d-flex features feature-primary pt-4 pb-4">
                <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                  <i className="uil uil-airplay text-primary"></i>
                </div>
                <div className="flex-1">
                  <h4 className="title">Software Development</h4>
                  <p className="text-muted para mb-0">
                    Creación de soluciones tecnológicas a medida..
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={4} md={6} className="col-12">
              <div className="d-flex features feature-primary pt-4 pb-4">
                <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                  <i className="uil uil-clapper-board text-primary"></i>
                </div>
                <div className="flex-1">
                  <h4 className="title">Software Outsourcing</h4>
                  <p className="text-muted para mb-0">
                    Confía en expertos externos para el desarrollo de software.
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={4} md={6} className="col-12">
              <div className="d-flex features feature-primary pt-4 pb-4">
                <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                  <i className="uil uil-users-alt text-primary"></i>
                </div>
                <div className="flex-1">
                  <h4 className="title">Staff Augmentation</h4>
                  <p className="text-muted para mb-0">
                    Amplía tu equipo con profesionales capacitados en
                    tecnología.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Features;
