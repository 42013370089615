import React, { useEffect } from 'react';

import Section from './Section';
import Features from './Features';
import Aboutus from './Aboutus';
import Business from './Business';
import MindPower from './MindPower';
import Cta from './Cta';
import Popup from '../../components/Layout/popup';
import NavBar from './NavBar';
import Footer2 from './../Pages/PageFooterLayouts/Footer2'; ///Pages/PageFooterLayouts/Footer2";

const ITSolutionTwo = () => {
  useEffect(() => {
    if(document.getElementById('topnav'))
      document.getElementById('topnav').classList.add('nav-sticky');

    if(document.body)
      document.body.classList = '';
  });

  return (
    <React.Fragment>
      <NavBar />
      {/* import section */}
      <Section />
      {/* import Features */}
      <Features />
      <section className="section">
        {/* <Aboutus /> */}
        <Business />
        {/* <MindPower /> */}
      </section>
      <Cta />
      <Footer2 />
    </React.Fragment>
  );
};
export default ITSolutionTwo;
