import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Form, Row } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import Creativity from '../../assets/images/illustrator/Creativity-bro.svg';
import ModalVideo from 'react-modal-video';
import Typist from 'react-typist';

const Section = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <section className="bg-half-100 d-table w-100">
        <Container className="container-h-465 pt-5">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="title-heading mt-4">
                <h6 className="mb-4">IT Software & Design</h6>
                <Typist>
                  <span className="heading text-primary mt-2 mb-3 element">
                  Innovación y Eficiencia <br /> A tu Alcance.
                  </span>
                </Typist>
                <p className="para-desc text-muted mt-3">
                  El futuro digital está a tu alcance. Nuestra empresa de
                  desarrollo de software te ofrece soluciones a medida,
                  impulsadas por la innovación y la eficiencia. Juntos,
                  crearemos la plataforma perfecta para tu negocio. ¡Hagamos
                  realidad tus objetivos tecnológicos!
                </p>
                <div className="mt-4 pt-2">
                  <Link to="#" className="btn btn-primary m-1">
                    <i className="uil uil-phone"></i> Ponte en contacto
                  </Link>{' '}
                  <Link
                    to="#"
                    onClick={() => setOpen(true)}
                    className="btn btn-icon btn-pills btn-primary m-1 lightbox"
                  >
                    <FeatherIcon icon="video" className="icons" />
                  </Link>
                  <small className="fw-bold text-uppercase text-light align-middle ms-1">
                    Ver ahora
                  </small>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <img src={Creativity} alt="" />
            </Col>
          </Row>
          <ModalVideo
            channel="youtube"
            isOpen={open}
            videoId="yba7hPeTSjk"
            onClose={() => setOpen(false)}
          />
        </Container>
      </section>

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Section;
